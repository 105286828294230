<template lang="pug">
#paper.A4.single
  section.invoice.sheet.padding-10mm
    b-container.h-100(fluid)
      .inner
        .header
          .left
            .brand KONUK
            .address
              | Divanyolu Cd. Alemdar Mh. Ticarethane Sk. No:10
              br
              | Sultanahmet / Istanbul +90 539 593 78 11
              br
              | sales@konuk.com
          .right.text-right
            strong.d-block.font-weight-bold #KONUK
            strong
        hr
        .invoice-owners
          .left
            .title.ml-1.mb-2.font-weight-bold TO:
            b-table-simple(striped borderless small)
              b-tr
                b-td.name(width="95px") Name:
                b-td {{ source.order_stack.passenger_name }}
              b-tr
                b-td.name(width="95px") Email:
                b-td {{ source.order_stack.passenger_email }}
              b-tr
                b-td.name(width="95px") Phone:
                b-td {{ source.order_stack.passenger_phone }}

          .right
            .title.ml-1.mb-2.font-weight-bold VOUCHER DETAILS:
            b-table-simple(striped borderless small)
              b-tr
                b-td.name(width="130px") Service:
                b-td.text-uppercase Transfer
              b-tr
                b-td.name(width="130px") Sale Date:
                b-td.text-uppercase {{ source.created_at }}
              b-tr
                b-td.name(width="130px") Payment Type:
                b-td.text-uppercase Cash To Driver
        hr
        .service.mt-4
          b-table-simple(striped borderless small)
            b-thead(head-variant="dark")
              b-tr
                b-th Service
                b-th Flight Code
                b-th Date / Time
                b-th Pax
                b-th Amount
            b-tbody
              b-tr
                b-td {{ fromData.name }} - {{ toData.name }}
                b-td {{ source.order_stack.flight_code }}
                b-td {{ source.order_stack.date }} {{ source.order_stack.time }}
                b-td {{ source.pax }}
                b-td {{ orderData.round_trip ? packageData.two_way_price : packageData.price }} {{ packageData.currency }}
              b-tr(v-if="orderData.round_trip")
                b-td {{ toData.name }} - {{ fromData.name }}
                b-td {{ source.order_stack.return_flight_code }}
                b-td {{ source.order_stack.return_date }} {{ source.order_stack.return_time }}
                b-td {{ source.pax }}
                b-td {{ orderData.round_trip ? packageData.two_way_price : packageData.price }} {{ packageData.currency }}
        .totals.mt-4
          b-row.justify-content-end
            b-col(lg="4")
              b-table-simple(striped borderless small)
                b-thead(head-variant="dark")
                  b-tr
                    b-th.text-center(colspan="2")
                b-tbody
                  b-tr
                    b-td Total:
                    b-td {{ total.toFormat('0.00') }} {{ packageData.currency }}
  //- pre {{ source }}
</template>

<script>
import axios from 'axios'
import Dinero from 'dinero.js'
import { CurrencyMixin } from '@/mixins/CurrencyMixin.js'

// const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
const END_POINT = 'external-transfer/v1/transfer'

export default {
  name: 'Home',
  components: {},
  mixins: [CurrencyMixin],
  data: () => ({
    source: [],
    order: []
  }),
  computed: {
    fromData () {
      return this.source?.service_stack?.from
    },
    toData () {
      return this.source?.service_stack?.to
    },
    packageData () {
      return this.source?.packages_stack
    },
    rateData () {
      return this.source?.rate_stack
    },
    orderData () {
      return this.source?.order_stack
    },
    total () {
      const vm = this
      const __price = vm.orderData.round_trip ? vm.source.packages_stack.two_way_price : vm.source.packages_stack.price
      let totalPrice = Dinero({ amount: vm.CM_FLATTEN(__price), currency: vm.source.service_stack.currency, precision: 2 })
      if (vm.orderData.round_trip) {
        totalPrice = totalPrice.multiply(2)
      }
      return totalPrice
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      const vm = this
      const uuid = vm.$route.params.uuid
      axios.post(`${END_POINT}/pre-reservation/${uuid}`)
        .then((r) => {
          vm.source = r.data.success ? r.data.data.source : []
          vm.orders = r.data.success ? r.data.data.orders : []
          if (vm.source.order_stack) { vm.loadFormOldData() }
          vm.init = true
        })
    }
  }
}
</script>

<style lang="scss">

// PAPER CSS
@page{margin:0}body{margin:0}.sheet{margin:0;overflow:hidden;position:relative;box-sizing:border-box;page-break-after:always}#paper.A3 .sheet{width:297mm;height:419mm}#paper.A3.landscape .sheet{width:420mm;height:296mm}#paper.A4 .sheet{width:210mm;height:296mm}#paper.A4.landscape .sheet{width:297mm;height:209mm}#paper.A5 .sheet{width:148mm;height:209mm}#paper.A5.landscape .sheet{width:210mm;height:147mm}body.letter .sheet{width:216mm;height:279mm}body.letter.landscape .sheet{width:280mm;height:215mm}body.legal .sheet{width:216mm;height:356mm}body.legal.landscape .sheet{width:357mm;height:215mm}.sheet.padding-10mm{padding:10mm}.sheet.padding-15mm{padding:15mm}.sheet.padding-20mm{padding:20mm}.sheet.padding-25mm{padding:25mm}@media screen{body{background:#e0e0e0}.sheet{background:#fff;box-shadow:0 .5mm 2mm rgba(0,0,0,.3);margin:5mm auto}}@media print{#paper.A3.landscape{width:420mm}#paper.A3,#paper.A4.landscape{width:297mm}#paper.A4,#paper.A5.landscape{width:210mm}#paper.A5{width:148mm}body.legal,body.letter{width:216mm}body.letter.landscape{width:280mm}body.legal.landscape{width:357mm}}

.single{
  .invoice{
    .header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .brand{
        font-weight: 300;
        font-size: 1.7rem;
      }
    }
    .invoice-owners{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
</style>
